import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useGeneratorQuery } from "wecall-config-lib";
import card_in_hamd from "../assets/card.svg";
import customers from "../assets/customers.svg";
import { ROUTES, sessionStorageRoutes } from "../component/Constant";

function CTA({ headline, subtitle, paragraph }) {
  const generatorQuery = useGeneratorQuery();

  useEffect(() => {
    sessionStorage.setItem(sessionStorageRoutes.lander, true);
  }, []);
  return (
    <>
      <div className="hero-container">
        <div className="headline-wrapper">
          <img src={customers}></img>
        </div>
        <div className="sub-hero-section">
          <div className="hero-section-wrapper">
            <div className="hero-section-wrapper-content">
              <h2>{subtitle}</h2>
              <p>{paragraph}</p>
            </div>
            <div className="cta">
              <Link
                id="cta-click"
                to={{ pathname: ROUTES.income, search: generatorQuery.get() }}
              >
                I Want My Card
              </Link>
              <span>37 Available Slots Left, Act Now!</span>
            </div>
          </div>
        </div>
      </div>
      <div className="card-container">
        <div className="mainbg">
          <img src={card_in_hamd} alt=""></img>
        </div>
      </div>
    </>
  );
}

export default CTA;
